import React, { useEffect, useMemo } from 'react'
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { RouteComponentProps } from '@reach/router'
import { BaseTemplate, DocumentTitle } from 'features/ui'
import { Root } from '../organisms'
import { $order, loadOrderFx } from '../store'

interface IParams {
  id: string
}

type TProps = RouteComponentProps<IParams>

export const OrderPage = ({ id }: TProps) => {
  const { formatMessage } = useIntl()
  const { Created, Id: OrderId, Number } = useStore($order)

  const pageTitle = useMemo(
    () => {
      if (!OrderId) return formatMessage({ id: 'loading' })

      return formatMessage({ id: 'order.title' }, {
        number: Number,
        date: moment(Created).utc().format('DD.MM.YYYY HH:mm:ss')
      })
    },
    [Created, formatMessage, OrderId, Number]
  )

  useEffect(
    () => {
      if (id && id.length === 36) {
        loadOrderFx(id)
      }
    },
    [id]
  )

  return (
    <BaseTemplate
      fullHeightLayer
      innerScrollbar={false}
      pageTitle={pageTitle}
    >
      <DocumentTitle>
        {pageTitle}
      </DocumentTitle>

      <Root />
    </BaseTemplate>
  )
}
