import React, { Fragment, ReactNode, useCallback, useMemo } from 'react'
import { useStore } from 'effector-react'
import cn from 'classnames'
import { Layout } from 'antd'
import { PedantLogo } from 'features/ui/atoms'
import { $drawerVisible, setDrawerVisible } from 'features/@core'
import { SiderMenu } from 'features/ui/molecules'
import { Header } from 'features/ui/organisms'
import * as classes from './style.module.less'

interface IProps {
  children: ReactNode
  fullHeightLayer?: boolean
  innerScrollbar?: boolean
  pageTitle?: ReactNode
}

export const BaseTemplate = ({
  children,
  fullHeightLayer,
  innerScrollbar = true,
  pageTitle = 'Pedant'
}: IProps) => {
  const drawerVisible = useStore($drawerVisible)

  const onSiderCollapse = useCallback(
    () => {
      setDrawerVisible(!drawerVisible)
    },
    [drawerVisible]
  )

  const layoutContentClasses = useMemo(
    () => {
      return cn(classes.content, {
        [classes.contentInnerScrollbar]: innerScrollbar
      })
    },
    [innerScrollbar]
  )

  const layoutInnerClasses = useMemo(
    () => {
      return cn(classes.inner, {
        [classes.innerFullHeight]: fullHeightLayer
      })
    },
    [fullHeightLayer]
  )

  return (
    <Fragment>
      <div className={classes.wrapper}>
        <div className={classes.outerLayer}>
          <div className={classes.side}>
            <Layout.Sider
              className={classes.sider}
              collapsed={!drawerVisible}
              onCollapse={onSiderCollapse}
              width={drawerVisible ? 260 : 80}
            >
              <PedantLogo />

              <SiderMenu preventNavigation={false} />
            </Layout.Sider>
          </div>

          <div className={classes.head}>
            <Header
              pageTitle={pageTitle}
            />
          </div>

          <div
            className={classes.main}
            id='content-parent'
          >
            <Layout.Content className={layoutContentClasses}>
              <div className={classes.innerLayer}>
                <div className={layoutInnerClasses}>
                  {children}
                </div>
              </div>
            </Layout.Content>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
