import { ComponentType } from 'react'

export interface IRoutesMap {
  [key: string]: ComponentType<any>
}

export enum OrderPhotoTypes {
  General = 'General',
  Insurance = 'Insurance'
}

export interface IAppSettings {
  InsuranceApprovedOrderStatusId: string
  InsuranceCheckOrderStatusId: string
  InsuranceRejectedOrderStatusId: string
  OrderPhotoTypes: {[key in OrderPhotoTypes]: string}
}
