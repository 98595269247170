import getBrowserLocale from 'browser-locale'
import { AvailableLocaleCodes } from '../types'

const availableLocaleCodes = Object.values(AvailableLocaleCodes)

export const getLocale = () => {
  const browserLocale = getBrowserLocale()

  const codeFromBrowserLocale = availableLocaleCodes.find(item =>
    item.toLowerCase().includes(browserLocale.toLowerCase())
  )

  if (!codeFromBrowserLocale) {
    return availableLocaleCodes[0]
  }

  return codeFromBrowserLocale
}
