import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import classes from './styles.module.less'

export interface IColoredCircleRendererParams<T = any, F extends keyof T = any> {
  getNameAndColor: (data: T, value: F) => ({ name: string, color?: string })
}

type IParams = IColoredCircleRendererParams & ICellRendererParams

export class ColoredCircleRenderer implements ICellRendererComp {
  private params: IParams | null = null

  public wrapper = document.createElement('div')

  public nameSpan = document.createElement('span')

  public colorSpan = document.createElement('span')

  public colorSpanWrapper = document.createElement('div')

  getGui () {
    return this.wrapper
  }

  init (params: IParams) {
    this.params = params

    const { name } = this.getData()

    this.computeColorStyles()
    this.colorSpan.classList.add(classes.colorSpan)

    this.nameSpan.innerText = name

    this.wrapper.classList.add(classes.wrapper)

    const nameSpanWrapper = document.createElement('div')
    nameSpanWrapper.classList.add(classes.nameSpanWrapper)

    const outerWrapper = document.createElement('div')
    outerWrapper.classList.add(classes.outerWrapper)

    this.colorSpanWrapper.appendChild(this.colorSpan)
    nameSpanWrapper.appendChild(this.nameSpan)

    outerWrapper.appendChild(this.colorSpanWrapper)
    outerWrapper.appendChild(nameSpanWrapper)

    this.wrapper.appendChild(outerWrapper)
  }

  computeColorStyles () {
    const { color } = this.getData()

    if (color) {
      this.colorSpanWrapper.style.display = ''
      this.colorSpan.style.backgroundColor = color
    } else {
      this.colorSpanWrapper.style.display = 'none'
      this.colorSpan.style.backgroundColor = ''
    }
  }

  refresh () {
    const { name } = this.getData()

    this.computeColorStyles()
    this.nameSpan.innerText = name

    return true
  }

  getData () {
    if (this.params && this.params.data && this.params.value) {
      return this.params.getNameAndColor(this.params.data, this.params.value)
    }

    return {
      name: ''
    }
  }
}
