import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community'
import { AgGridTable, TableActions, ITableActionsParams } from 'features/ag-grid'
import { pedantBaseCurrencyFix } from 'utils'
import { $order, removeWork } from '../../../store'
import { IAgGridColDef } from 'types'
import { IExtServiceAndPart } from '../../../types'
import * as classes from './style.module.less'

export const ServicesAndParts = () => {
  const { formatMessage, formatNumber } = useIntl()
  const { Currency, ServicesAndParts } = useStore($order)

  const rowData = useMemo(
    () => ServicesAndParts?.length ? ServicesAndParts : [],
    [ServicesAndParts]
  )

  const defaultColDef: ColDef = useMemo(
    () => ({
      resizable: true
    }),
    []
  )

  const colDefs: ColDef[] = useMemo(
    () => {
      const actions: ColDef = {
        cellClass: 'ag-disable-cell-highlight',
        headerName: formatMessage({ id: 'order.fields.servicesAndPartsTable.actions' }),
        cellRenderer: TableActions,
        cellRendererParams: {
          actions: [
            {
              className: classes.removeAction,
              condition: params => Boolean(params.data?.Id),
              onClick: (params) => {
                if (params.data?.Id) removeWork(params.data.Id)
              },
              text: formatMessage({ id: 'order.fields.servicesAndPartsTable.remove' }),
              type: 'link'
            }
          ]
        } as ITableActionsParams<IExtServiceAndPart>
      }

      const serviceName: IAgGridColDef<IExtServiceAndPart, 'Service'> = {
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.service.name' }),
        field: 'Service',
        valueGetter: params => params.data.Service?.Name || ''
      }

      const serviceCost: IAgGridColDef<IExtServiceAndPart, 'ServiceCost'> = {
        cellStyle: { textAlign: 'right' },
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.serviceCost' }),
        field: 'ServiceCost',
        valueFormatter: ({ value }) => (
          formatNumber(value, {
            style: Currency ? 'currency' : 'decimal',
            currency: Currency ? pedantBaseCurrencyFix(Currency) : undefined
          })
        )
      }

      const partName: IAgGridColDef<IExtServiceAndPart, 'Part'> = {
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.part.name' }),
        field: 'Part',
        valueGetter: params => params.data.Part?.Name || ''
      }

      const partCost: IAgGridColDef<IExtServiceAndPart, 'PartCost'> = {
        cellStyle: { textAlign: 'right' },
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.partCost' }),
        field: 'PartCost',
        valueFormatter: ({ value }) => (
          formatNumber(value, {
            style: Currency ? 'currency' : 'decimal',
            currency: Currency ? pedantBaseCurrencyFix(Currency) : undefined
          })
        )
      }

      const warrantyPeriod: IAgGridColDef<IExtServiceAndPart, 'WarrantyPeriod'> = {
        cellStyle: { textAlign: 'right' },
        headerName: formatMessage({ id: 'order.fields.warrantyPeriod' }),
        field: 'WarrantyPeriod',
      }

      const extendedWarranty: IAgGridColDef<IExtServiceAndPart, 'ExtendedWarranty'> = {
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.extendedWarranty' }),
        field: 'ExtendedWarranty',
        valueGetter: params => params.data.ExtendedWarranty
          ? formatMessage({ id: 'yes' })
          : formatMessage({ id: 'no' })
      }

      const writeOffDebtByLine: IAgGridColDef<IExtServiceAndPart, 'WriteOffDebtByLine'> = {
        headerName: formatMessage({ id: 'order.fields.servicesAndParts.writeOffDebtByLine' }),
        field: 'WriteOffDebtByLine',
        valueGetter: params => params.data.WriteOffDebtByLine
          ? formatMessage({ id: 'yes' })
          : formatMessage({ id: 'no' })
      }

      return [
        actions,
        serviceName,
        serviceCost,
        partName,
        partCost,
        warrantyPeriod,
        extendedWarranty,
        writeOffDebtByLine
      ]
    },
    [Currency, formatMessage, formatNumber]
  )

  const onFirstDataRendered = useCallback(
    ({ columnApi }: FirstDataRenderedEvent) => {
      columnApi.autoSizeAllColumns()
    },
    []
  )

  return (
    <div className={classes.wrapper}>
      <AgGridTable
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onFirstDataRendered={onFirstDataRendered}
        rowData={rowData}
      />
    </div>
  )
}
