import { createEffect } from 'effector'
import { AxiosError, AxiosResponse } from 'axios'
import { stringify } from 'querystring'
import { pedantAuthApi, pedantBaseApi } from 'api'
import { IAuthUserData, IGetTokenParams, ITokenData, ITokenErrorData } from '../types'
import { configureApi } from '../utils'

export const configureApiFx = createEffect({
  handler () {
    const authData = localStorage.auth && JSON.parse(localStorage.auth)

    if (!authData.access_token && window.location.pathname !== '/login') {
      window.location.href = '/login'
      throw new Error('Auth data not found')
    }

    configureApi()
  }
})

export const getTokenFx = createEffect<
  IGetTokenParams,
  AxiosResponse<ITokenData>,
  AxiosError<ITokenErrorData>
>({
  handler (params: IGetTokenParams) {
    const { REACT_APP_AUTH_CLIENT_ID } = process.env

    const request = stringify({
      ...params,
      client_id: REACT_APP_AUTH_CLIENT_ID || '',
      grant_type: 'password'
    })

    return pedantAuthApi.post<ITokenData>('/connect/token', request)
  }
})

export const refreshTokenFx = createEffect({
  handler () {
    const { REACT_APP_AUTH_CLIENT_ID } = process.env
    const authData: ITokenData | undefined = localStorage.auth && JSON.parse(localStorage.auth)

    if (!authData?.refresh_token) throw new Error('Refresh token not found')

    const requestData = stringify({
      client_id: REACT_APP_AUTH_CLIENT_ID || '',
      grant_type: 'refresh_token',
      refresh_token: authData.refresh_token
    })

    return pedantAuthApi.post<ITokenData>('/connect/token', requestData)
  }
})

export const loadAuthUserDataFx = createEffect({
  handler () {
    return pedantBaseApi.get<IAuthUserData>('/auth/userinfo')
  }
})

export const removeTokenFx = createEffect({
  async handler () {
    const { REACT_APP_AUTH_CLIENT_ID } = process.env
    const authData: ITokenData | undefined = localStorage.auth && JSON.parse(localStorage.auth)

    if (!authData?.refresh_token || !authData.access_token) throw new Error('Auth data for revocation not found')

    const revokeAccessTokenParams = stringify({
      token: authData.access_token,
      token_type_hint: 'access_token',
      client_id: REACT_APP_AUTH_CLIENT_ID || ''
    })

    const revokeRefreshTokenParams = stringify({
      token: authData.refresh_token,
      token_type_hint: 'refresh_token',
      client_id: REACT_APP_AUTH_CLIENT_ID || ''
    })

    const revocation = Promise.all([
      pedantBaseApi.post('/auth/revocation', revokeAccessTokenParams),
      pedantBaseApi.post('/auth/revocation', revokeRefreshTokenParams)
    ])

    revocation.then(() => {
      localStorage.removeItem('auth')
      window.location.href = '/login'
    })

    return revocation
  }
})
