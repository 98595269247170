import React, { Fragment } from 'react'
import { DocumentTitle } from 'features/ui'
import { Root } from '../organisms'

export const LoginPage = () => {
  return (
    <Fragment>
      <DocumentTitle useIntlKey>
        login.title
      </DocumentTitle>

      <Root />
    </Fragment>
  )
}
