import React from 'react'
import { useStore } from 'effector-react'
import { Redirect } from '@reach/router'
import { $userIsLoggedIn } from '../store'

export const withAuth = <T extends {}>(WrappingComponent: React.ComponentType<T>) => (props: T) => {
  const isLogged = useStore($userIsLoggedIn)

  if (isLogged) {
    return <WrappingComponent {...props} />
  } else {
    return (
      <Redirect
        from=''
        noThrow
        to={'/login'}
      />
    )
  }
}
