import React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { CheckOutlined } from '@ant-design/icons'
import cn from 'classnames'
import * as classes from './style.module.less'

interface IProps extends ButtonProps {}

export const CheckButton = ({ className, ...props }: IProps) => (
  <Button
    className={cn(classes.checkButton, className)}
    type='primary'
    {...props}
  >
    <CheckOutlined />
  </Button>
)

