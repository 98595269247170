import React, { useEffect } from 'react'
import { OrdersPagination } from '../pagination'
import { Table } from '../table'
import { TopBar } from '../top-bar'
import { initialize, reset } from '../../store'
import * as classes from './style.module.less'

export const Root = () => {
  useEffect(
    () => {
      initialize()

      return () => {
        reset()
      }
    },
    []
  )

  return (
    <div className={classes.wrapper}>
      <TopBar />

      <Table />

      <div className={classes.pagination}>
        <OrdersPagination />
      </div>
    </div>
  )
}
