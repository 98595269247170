import { createEffect } from 'effector'
import { pedantBaseApi, pedantInsuranceApi } from 'api'
import {
  IInsurancePhotosRequest,
  IInsuranceStatusChangeRequest,
  IOrder,
  IOrderPhoto,
  ITableOrder,
  ITestsResults
} from '../types'

export const loadOrderFx = createEffect({
  handler (id: string) {
    return pedantBaseApi.get<IOrder>(`/api/insurance-orders/order/${id}`)
  }
})

export const processOrderDataFx = createEffect({
  handler (data: IOrder): ITableOrder {
    return ({
      ...data,
      ServicesAndParts: data.ServicesAndParts.map((item) => ({
        ...item,
        ExtendedWarranty: item.Warranty6Month || item.Warranty12Month
      }))
    })
  }
})

export const loadTestsResultsFx = createEffect({
  handler (sessionId: string) {
    return pedantInsuranceApi.get<ITestsResults>(`/session/test-results/${sessionId}`)
  }
})

export const updateOrderFx = createEffect({
  handler (params: IInsuranceStatusChangeRequest) {
    return pedantBaseApi.post<IOrder>(`/api/insurance-orders/order/${params.orderId}`, params)
  }
})

export const loadInsurancePhotosFx = createEffect({
  handler (params: IInsurancePhotosRequest) {
    return pedantBaseApi.get<IOrderPhoto[]>('api/orders/documentPhotos/list', {
      params
    })
  }
})
