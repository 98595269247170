import React from 'react'
import { useStore } from 'effector-react'
import { Redirect } from '@reach/router'
import { $userIsLoggedIn } from '../store'

export const withRedirectIfAuth = <T extends {}>(
  to: string
) => (
    WrappingComponent: React.ComponentType<T>
  ) => (
    props: T
  ) => {
    const isLogged = useStore($userIsLoggedIn)

    if (isLogged) {
      return (
        <Redirect
          from=''
          noThrow
          to={to}
        />
      )
    } else {
      return <WrappingComponent {...props} />
    }
  }
