import React, { useCallback, useMemo } from 'react'
import { useStore } from 'effector-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, DatePicker, Input, Row } from 'antd'
import { RangeValue } from 'rc-picker/lib/interface'
import { Moment } from 'moment'
import { CheckButton } from 'features/ui'
import { $filters, applyFilters, setFilters } from '../../store'
import { OrderInsuranceStatuses } from '../../types'
import { ROW_GUTTER } from 'app-constants'

export const TopBar = () => {
  const { formatMessage } = useIntl()
  const { from, till, orderNumber, status } = useStore($filters)

  const orderNumberPlaceholder = useMemo(
    () => formatMessage({ id: 'orders.topBar.orderNumber' }),
    [formatMessage]
  )

  const onChange = useCallback(
    (dates: RangeValue<Moment>) => {
      if (!dates || !dates[0] || !dates[1]) return

      const [from, till] = dates as [Moment, Moment]
      setFilters({ from, till })
    },
    []
  )

  const onCheckButtonClick = useCallback(
    () => applyFilters(),
    []
  )

  const onPendingClick = useCallback(
    () => setFilters({ status: OrderInsuranceStatuses.Pending }),
    []
  )

  const onDoneClick = useCallback(
    () => setFilters({ status: OrderInsuranceStatuses.Done }),
    []
  )

  const onRejectedClick = useCallback(
    () => setFilters({ status: OrderInsuranceStatuses.Rejected }),
    []
  )

  const onOrderNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters({ orderNumber: event.target.value || null })
    },
    []
  )

  return (
    <Row gutter={ROW_GUTTER}>
      <Col>
        <Button
          onClick={onPendingClick}
          type={status === OrderInsuranceStatuses.Pending ? 'primary' : 'default'}
        >
          <FormattedMessage id='orders.topBar.pending'/>
        </Button>
      </Col>

      <Col>
        <Button
          onClick={onDoneClick}
          type={status === OrderInsuranceStatuses.Done ? 'primary' : 'default'}
        >
          <FormattedMessage id='orders.topBar.done'/>
        </Button>
      </Col>

      <Col>
        <Button
          onClick={onRejectedClick}
          type={status === OrderInsuranceStatuses.Rejected ? 'primary' : 'default'}
        >
          <FormattedMessage id='orders.topBar.rejected'/>
        </Button>
      </Col>

      <Col>
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={[false, false]}
          onChange={onChange}
          value={[from, till]}
        />
      </Col>

      <Col>
        <Input
          allowClear
          maxLength={9}
          minLength={3}
          onChange={onOrderNumberChange}
          placeholder={orderNumberPlaceholder}
          value={orderNumber || ''}
        />
      </Col>

      <Col>
        <CheckButton onClick={onCheckButtonClick} />
      </Col>
    </Row>
  )
}
