import { combine, createStore, forward, merge, sample } from 'effector'
import moment from 'moment'
import { applyFilters, initialize, reset, setFilters, setPagination } from './events'
import { loadOrderListFx } from './effects'
import { IFilters, IOrderItem, IPagination, OrderInsuranceStatuses } from '../types'

const DEFAULT_PAGESIZE = 20

/** Stores */

/** Filters */
const $filters = createStore<IFilters>({
  from: moment().utc(true).startOf('month'),
  till: moment().utc(true).endOf('month'),
  orderNumber: null,
  status: OrderInsuranceStatuses.Pending
})
  .on(setFilters, (state, payload) => ({ ...state, ...payload }))
  .reset(reset)

/** Pagination */
const $pagination = createStore<IPagination>({
  skip: 0,
  top: DEFAULT_PAGESIZE
})
  .on(setPagination, (state, payload) => ({ ...state, ...payload }))
  .reset(reset)

/** Orders list */
const $ordersList = createStore<IOrderItem[]>([])
  .on(loadOrderListFx.doneData, (_, { data }) => data.data)
  .reset(reset)

/** Total entries count */
const $ordersTotalCount = createStore<number>(0)
  .on(loadOrderListFx.doneData, (_, { data }) => data.count)
  .reset(reset)

/** End of stores */

/** Computed */

const $tableLoading = loadOrderListFx.pending

/** End of computed */

/** Watchers */

/** Load orders after initialization */
forward({
  from: initialize,
  to: applyFilters
})

/** Load data on filters apply and page size change */
const onLoadData = merge([
  applyFilters,
  setPagination.filterMap(({ skip }) => skip)
])

/** Reset skip count on filters apply */
forward({
  from: applyFilters,
  to: setPagination.prepend(() => ({ skip: 0 }))
})

/** Load data on filters apply and page change */
sample({
  source: combine([$filters, $pagination]),
  clock: onLoadData,
  fn: ([filters, pagination]) => ({ ...filters, ...pagination }),
  target: loadOrderListFx
})

/** End of watchers */

export {
  $filters,
  $ordersList,
  $ordersTotalCount,
  $pagination,
  $tableLoading,
  applyFilters,
  initialize,
  reset,
  setFilters,
  setPagination
}
