import { combine, createStore, forward, merge } from 'effector'
import { configureApiFx, getTokenFx, loadAuthUserDataFx, refreshTokenFx, removeTokenFx } from './effects'
import { IAuthUserData, ITokenErrorData, UserRoles } from '../types'

/** Stores */

/** Auth user data */
const $auth = createStore<IAuthUserData>({
  sub: '',
  name: '',
  preferredUsername: '',
  email: '',
  emailVerified: [],
  phoneNumber: '',
  phoneNumberVerified: false
})
  .on(loadAuthUserDataFx.doneData, (_, { data }) => data)

/** Auth error */
const $authError = createStore<ITokenErrorData | null>(null)
  .on(getTokenFx.failData, (_, error) => error.response?.data || null)
  .reset(merge([getTokenFx.done, removeTokenFx]))


/** User is logged in */
const $userIsLoggedIn = createStore(false)
  .on(loadAuthUserDataFx.done, () => true)

/** End of stores */

/** Computed */

const $authLoading = combine([
  configureApiFx.pending,
  loadAuthUserDataFx.pending
])
  .map(state => state.some(Boolean))

const $userRoles = $auth.map(state => state.role !== undefined ? ([] as UserRoles[]).concat(state.role) : [])

/** End of computed */

/** Watchers */

/** Token received event */
const onTokenReceived = merge([getTokenFx.doneData, refreshTokenFx.doneData])

/** Save token data to local storage */
onTokenReceived.watch(({ data }) => {
  const tokenData = { ...data }
  delete tokenData.id_token

  localStorage.setItem('auth', JSON.stringify(tokenData))
})

/** Configure api after get token */
forward({
  from: getTokenFx.done,
  to: configureApiFx
})

/** Load user data after configuring api */
forward({
  from: configureApiFx.done,
  to: loadAuthUserDataFx
})

/** End of watchers */

export {
  $auth,
  $authError,
  $authLoading,
  $userIsLoggedIn,
  $userRoles,
  configureApiFx,
  getTokenFx,
  refreshTokenFx,
  removeTokenFx
}
