import React, { useCallback, useMemo, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStore } from 'effector-react'
import cn from 'classnames'
import { Avatar, Dropdown, Menu } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, MoreOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { $auth, $drawerVisible, removeTokenFx, setDrawerVisible } from 'features/@core'
import * as classes from './style.module.less'

interface IProps {
  pageTitle: ReactNode
  className?: string
}

export const Header = ({
  className,
  pageTitle
}: IProps) => {
  const drawerVisible = useStore($drawerVisible)
  const { name: username } = useStore($auth)

  const onSignOutClick = useCallback(
    () => {
      removeTokenFx()
    },
    []
  )

  const settingsDropdownMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onSignOutClick}>
          <UserDeleteOutlined />
          <FormattedMessage id='login.signOut' />
        </Menu.Item>
      </Menu>
    ),
    [onSignOutClick]
  )

  const DrawerIcon = useMemo(
    () => drawerVisible ? MenuFoldOutlined : MenuUnfoldOutlined,
    [drawerVisible]
  )

  const onSetDrawerVisible = useCallback(
    () => {
      setDrawerVisible(!drawerVisible)
    },
    [drawerVisible]
  )

  const twoFirstLettersOfName = useMemo(
    () => {
      return username
        .split(' ')
        .slice(0, 2)
        .reduce((letters, word) => letters + word[0], '')
    },
    [username]
  )

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.headerRow}>
        <div className={classes.leftSide}>
          <DrawerIcon onClick={onSetDrawerVisible} />

          <span className={classes.pageTitle}>
            {pageTitle}
          </span>
        </div>

        <div>
          <Dropdown overlay={settingsDropdownMenu}>
            <div className={classes.userProfileInfo}>
              <Avatar size='small'>
                {twoFirstLettersOfName}
              </Avatar>

              <span>
                {username}
              </span>

              <MoreOutlined className={classes.headerIcon} />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
