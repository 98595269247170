import { combine, createStore, guard, merge, sample } from 'effector'
import { $loadedAppSettings } from 'features/@core'
import {
  askConfirmation,
  confirmationReceived,
  initialize,
  removeWork,
  reset,
  setInsurancePhotos,
  setTestsResults
} from './events'
import { loadInsurancePhotosFx, loadOrderFx, loadTestsResultsFx, processOrderDataFx, updateOrderFx } from './effects'
import { IOrderPhoto, ITableOrder, ITestsResults } from '../types'

/** Stores */

const $order = createStore<ITableOrder>({
  Appearance: '',
  ColorName: '',
  Created: null,
  Currency: null,
  CustomModelName: '',
  CustomerName: '',
  CustomerPhone: null,
  CustomerPhoneCode: null,
  Id: '',
  IMEI: '',
  InsuranceContract: null,
  ModelName: '',
  Number: '',
  OrderMalfunctions: [],
  OrderStatusId: null,
  OrderStatusName: '',
  OrderTests: [],
  Password: '',
  ReceiverNotes: '',
  SerialNumber: '',
  ServiceCenterName: '',
  ServicesAndParts: [],
  TechnicianNotesForCertificateOfCompletion: ''
})
  .on(processOrderDataFx.doneData, (_, payload) => payload)
  .on(removeWork, (state, payload) => ({
    ...state,
    ServicesAndParts: state.ServicesAndParts.filter(item => item.Id !== payload)
  }))
  .reset(reset)

/** Tests results */
const $testsResults = createStore<ITestsResults>({
  modelId: '',
  modelName: '',
  testsResults: []
})
  .on(setTestsResults, (_, payload) => payload)
  .reset(reset)

/** Insurance photos */
const $insurancePhotos = createStore<IOrderPhoto[]>([])
  .on(setInsurancePhotos, (_, payload) => payload)
  .reset(reset)

/** Initialization state */
const $initialized = createStore<boolean>(false)
  .on(initialize, () => true)
  .reset(reset)

/** List of removed works */
const $worksRemoved = createStore<string[]>([])
  .on(removeWork, (state, payload) => [...state, payload])
  .reset(reset)

/** End of stores */

/** Computed */

const $loading = combine([loadOrderFx.pending, updateOrderFx.pending])
  .map(state => state.some(Boolean))

/** Sum of all works cost */
const $totalCostOfWork = $order.map(({ ServicesAndParts }) => ServicesAndParts
  .reduce<number>(
    (sum, serviceAndPart) => sum + serviceAndPart.ServiceCost,
    0
  ))

/** End of computed */

/** Watchers */

/** Process loaded order data */
guard({
  source: merge([loadOrderFx.doneData, updateOrderFx.doneData]).filterMap(({ data }) => data),
  filter: $initialized,
  target: processOrderDataFx
})

/** Load tests results */
guard({
  source: loadOrderFx.doneData.filterMap(({ data }) => data.InsuranceContract?.SessionId),
  filter: $initialized,
  target: loadTestsResultsFx
})

/** Put tests results into store */
guard({
  source: loadTestsResultsFx.doneData.filterMap(({ data }) => data),
  filter: $initialized,
  target: setTestsResults
})

/** Guarded confirmation event */
const onConfirmReceived = guard({
  source: confirmationReceived,
  filter: $initialized
})

/** Update order after confirmation received */
sample({
  source: [$order, $totalCostOfWork, $worksRemoved],
  clock: onConfirmReceived,
  fn: ([{ Id: orderId }, totalCostOfWork, removedWorks], { approved }) => ({
    approved,
    orderId,
    removedWorks,
    totalCostOfWork
  }),
  target: updateOrderFx
})

/** Load insurance photos */
sample({
  source: $loadedAppSettings,
  clock: loadOrderFx.doneData,
  fn: ({ OrderPhotoTypes }, { data }) => ({
    orderId: data.Id,
    typeId: OrderPhotoTypes.Insurance
  }),
  target: loadInsurancePhotosFx
})

/** Put loaded photos into store */
guard({
  source: loadInsurancePhotosFx.doneData.filterMap(({ data }) => data),
  filter: $initialized,
  target: setInsurancePhotos
})

/** End of watchers */

export {
  $initialized,
  $insurancePhotos,
  $loading,
  $order,
  $testsResults,
  $totalCostOfWork,
  $worksRemoved,
  askConfirmation,
  confirmationReceived,
  initialize,
  loadOrderFx,
  removeWork,
  reset
}
