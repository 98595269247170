import React, { CSSProperties, ReactNode, useMemo } from 'react'
import MaterialSpinner from 'react-spinner-material'
import cn from 'classnames'
import classes from './styles.module.less'

interface IProps {
  children?: ReactNode
  spinning?: boolean
  wrapperStyles?: CSSProperties
  wrapperClassName?: string
  size?: number
  color?: string
}

export const Spinner = ({
  children,
  spinning,
  wrapperStyles,
  wrapperClassName,
  size = 50,
  color
}: IProps) => {
  const childrenWrapperClassName = useMemo(
    () => {
      return cn(classes.childrenWrapper, {
        [classes.fade]: spinning
      })
    },
    [spinning]
  )

  return (
    <div
      className={cn(classes.wrapper, wrapperClassName)}
      style={wrapperStyles}
    >
      <div className={classes.icon}>
        <MaterialSpinner
          color={color}
          radius={size}
          visible={spinning || false}
        />
      </div>

      <div className={childrenWrapperClassName}>
        {children}
      </div>
    </div>
  )
}
