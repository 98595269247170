import { combine, createStore, forward } from 'effector'
import { $authLoading, $userIsLoggedIn, configureApiFx } from '../features/auth'
import { $intlLoading, getLocaleFx } from '../features/intl'
import { initializeApp, setDrawerVisible } from './events'
import { loadAppSettingsFx } from './effects'
import { IAppSettings } from '../types'

/** Stores */

/** App settings from backend */
const $appSettings = createStore<IAppSettings | null>(null)
  .on(loadAppSettingsFx.doneData, (_, { data }) => data)

/** End of stores */

/** Computed */

/** App is initializing */
const $appLoading = combine([
  combine([
    $appSettings.map(state => state === null),
    $userIsLoggedIn
  ]).map(([appSettingsIsNotLoaded, userIsLoggedIn]) => appSettingsIsNotLoaded && userIsLoggedIn),
  $authLoading,
  $intlLoading
])
  .map(state => state.some(Boolean))

/** App settings for loaded app
 * @see $appLoading
 */
const $loadedAppSettings = $appSettings.map(state => state as IAppSettings)

/** Drawer visible */
const $drawerVisible = createStore(false)
  .on(setDrawerVisible, (_, payload) => payload)

/** End of computed */

/** Watchers */

/** App initialization: load locale data */
forward({
  from: initializeApp,
  to: getLocaleFx
})

/** App initialization: configure api and load user data */
forward({
  from: getLocaleFx.done,
  to: configureApiFx
})

/** Load app settings after api configuration */
forward({
  from: configureApiFx.done,
  to: loadAppSettingsFx
})

/** End of watchers */

export {
  $appLoading,
  $appSettings,
  $drawerVisible,
  $loadedAppSettings,
  initializeApp,
  setDrawerVisible
}
