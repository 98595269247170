import { BookOutlined } from '@ant-design/icons'
import { IMenuItem } from './types'

export const menuItems: IMenuItem[] = [
  {
    allowedRoles: null,
    icon: BookOutlined,
    newTab: false,
    route: '/',
    translateKey: 'orders.title',
    visible: true
  }
]
