import { combine, createStore, forward } from 'effector'
import { Locale } from 'antd/lib/locale-provider'
import { intlReset } from './events'
import { changeLocalizationFx, getLocaleFx } from './effects'

/** Stores */

const $antdLocale = createStore<Locale>({ locale: '' })
  .on(changeLocalizationFx.doneData, (_, { antdIntlLanguageModule }) => antdIntlLanguageModule)
  .reset(intlReset)

const $locale = createStore<string>('ru')
  .on(getLocaleFx.doneData, (_, { short }) => short)
  .reset(intlReset)

const $localeCode = createStore<string>('ru-RU')
  .on(getLocaleFx.doneData, (_, { full }) => full)
  .reset(intlReset)

const $translations = createStore<{ [key: string]: string }>({})
  .on(changeLocalizationFx.doneData, (_, { translations }) => translations)
  .reset(intlReset)

/** End of stores */

/** Computed */

const $intlLoading = combine([
  $antdLocale.map(state => !state.locale),
  $translations.map(state => Object.keys(state).length === 0),
  changeLocalizationFx.pending,
  getLocaleFx.pending
])
  .map(state => state.some(Boolean))

/** End of computed */

/** Watchers */

forward({
  from: getLocaleFx.doneData.map(({ full, short }) => ({ locale: short, localeCode: full })),
  to: changeLocalizationFx
})

/** End of watchers */

export {
  $antdLocale,
  $locale,
  $localeCode,
  $translations,
  $intlLoading,
  getLocaleFx,
  intlReset
}
