import React, { useMemo } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useStore } from 'effector-react'
import { Col, Form, Input, Row } from 'antd'
import { pedantBaseCurrencyFix } from 'utils'
import { $order, $totalCostOfWork } from '../../store'
import { ROW_GUTTER } from 'app-constants'
import * as classes from './style.module.less'

export const BaseValues = () => {
  const { ColorName, Currency, CustomerName, CustomerPhone, CustomerPhoneCode, CustomModelName, InsuranceContract,
    ModelName, OrderStatusName } = useStore($order)
  const totalCostOfWork = useStore($totalCostOfWork)

  const customerNameWithPhone = useMemo(
    () => `${CustomerName} (${(CustomerPhoneCode || '').trim()}${(CustomerPhone || '').trim()})`,
    [CustomerName, CustomerPhone, CustomerPhoneCode]
  )

  return (
    <Form layout='vertical'>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.customerName' />}>
            <Input
              readOnly
              value={customerNameWithPhone}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={<FormattedMessage id='order.fields.modelName' />}>
            <Input
              readOnly
              value={ModelName}
            />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item label={<FormattedMessage id='order.fields.customModelName' />}>
            <Input
              readOnly
              value={CustomModelName || ''}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.colorName' />}>
            <Input
              readOnly
              value={ColorName}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col
          className={classes.textValues}
          span={8}
        >
          <Form.Item>
            <FormattedMessage
              id='order.fields.totalCostOfWork'
              values={{
                value: (
                  <FormattedNumber
                    currency={Currency ? pedantBaseCurrencyFix(Currency) : undefined}
                    style={Currency ? 'currency' : undefined}
                    value={totalCostOfWork}
                  />
                )
              }}
            />
          </Form.Item>

          <Form.Item>
            <FormattedMessage
              id='order.fields.insuranceNumber'
              values={{ value: InsuranceContract?.Number || '' }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.orderStatusName' />}>
            <Input
              readOnly
              value={OrderStatusName}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
