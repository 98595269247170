import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { Spinner } from 'features/ui'
import { BaseValues } from '../base-values'
import { OrderTabs } from '../tabhost'
import { TopBar } from '../top-bar'
import { $loading, initialize, reset } from '../../store'

export const Root = () => {
  const loading = useStore($loading)

  useEffect(
    () => {
      initialize()

      return () => {
        reset()
      }
    },
    []
  )

  return (
    <div>
      <TopBar />

      <Spinner spinning={loading}>
        <BaseValues />

        <OrderTabs />
      </Spinner>
    </div>
  )
}
