import { createEffect } from 'effector'
import { pedantBaseApi } from 'api'
import { IResponse, TRequestParams } from '../types'

export const loadOrderListFx = createEffect({
  handler (params: TRequestParams) {
    return pedantBaseApi.get<IResponse>('/api/insurance-orders/list', {
      params: {
        ...params,
        from: params.from.toDate(), // without toDate() will be problem with ""
        till: params.till.toDate()
      }
    })
  }
})
