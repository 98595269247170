import React, { useCallback, useMemo } from 'react'
import { useStore } from 'effector-react'
import { Pagination } from 'antd'
import { $pagination, $ordersTotalCount, setPagination } from '../../store'
import { IPagination } from '../../types'

export const OrdersPagination = () => {
  const { skip, top } = useStore($pagination)
  const totalCount = useStore($ordersTotalCount)

  const pageSizeOptions = useMemo(
    () => ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
    []
  )

  const currentPage = useMemo(
    () => top > 0 ? (top + skip) / top : 1,
    [skip, top]
  )

  const onChange = useCallback(
    (page: number, pageSize?: number) => {
      const pagination: Partial<IPagination> = { top: pageSize, skip: top * (page - 1) }
      if (!pageSize || pageSize === top) delete pagination.top
      setPagination(pagination)
    },
    [top]
  )

  return (
    <Pagination
      current={currentPage}
      onChange={onChange}
      pageSize={top}
      pageSizeOptions={pageSizeOptions}
      showSizeChanger
      total={totalCount}
    />
  )
}
