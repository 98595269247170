export interface IGetTokenParams {
  username: string,
  password: string
}

/* eslint-disable camelcase */
export interface ITokenData {
  access_token: string
  expires_in: number
  id_token?: string
  refresh_token: string
  scope: string
  token_type: string
}

export interface ITokenErrorData {
  error: string | ITokenErrors
  error_description?: ITokenErrorsDescriptions
}
/* eslint-enable camelcase */

export enum ITokenErrors {
  InvalidClient = 'invalid_client',
  InvalidGrant = 'invalid_grant',
  Unathorized = 'unauthorized'
}

export enum ITokenErrorsDescriptions {
  InvalidCredentials = 'invalid_username_or_password',
  UnauthorizedForClient = 'you_are_not_authorized_for_this_client'
}

export interface IAuthUserData {
  sub: string
  emailVerified: []
  name: string
  email: string
  preferredUsername: string
  phoneNumber: string
  phoneNumberVerified: boolean
  role?: UserRoles | UserRoles[]
}

export enum UserRoles {
  Administrator = 'Administrator',
  DocReader = 'DocReader', // allowed to read orders, cashboxes etc from all sc
  FinBalance = 'FinBalance', // finance-balance and finance-balance-summary report access
  Franchisee = 'Franchisee',
  FranchiseeRating = 'FranchiseeRating', // franchisee rating access
  IT = 'IT',
  OrganizationTrustedUser = 'OrganizationTrustedUser',
  SalesDepartment = 'SalesDepartment',
  SocialNetworks = 'SocialNetworks',
  TrueFranchisee = 'TrueFranchisee'
}
