import React from 'react'
import * as classes from './style.module.less'

export const PedantLogo = () => {
  return (
    <div className={classes.logo}>
      <img
        alt='Pedant.ru'
        src='/logo.svg'
        style={{ objectFit: 'contain' }}
      />
    </div>
  )
}
