import React, { useCallback, useMemo } from 'react'
import { Location, LocationContext, Router as ReachRouter, WindowLocation } from '@reach/router'
import { mapRoutes } from './utils'
import { withRedirectIfAuth } from 'features/@core'
import { LoginPage } from 'features/login'
import { ordersRoutes } from 'features/orders'

export const Router = () => {
  const authRoutes = useMemo(
    () => ({
      '/login': withRedirectIfAuth('/')(LoginPage)
    }),
    []
  )

  const renderRoutes = useCallback(
    (location: WindowLocation) => {
      return (
        <ReachRouter location={location}>
          {mapRoutes(authRoutes)}
          {mapRoutes(ordersRoutes)}
        </ReachRouter>
      )
    },
    [authRoutes]
  )

  const locationCallback = useCallback(
    ({ location }: LocationContext) => renderRoutes(location),
    [renderRoutes]
  )

  return (
    <Location children={locationCallback} />
  )
}
