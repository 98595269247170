import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community'
import { AgGridTable } from 'features/ag-grid'
import { $order } from '../../../store'
import { IAgGridColDef } from 'types'
import { IOrderTest } from '../../../types'
import * as classes from './style.module.less'

export const Testing = () => {
  const { formatMessage } = useIntl()

  const order = useStore($order)

  const colDefs: ColDef[] = useMemo(
    () => {
      const testName: IAgGridColDef<IOrderTest, 'TestName'> = {
        headerName: formatMessage({ id: 'order.orderTests.testName' }),
        field: 'TestName'
      }

      const beforeRepairStateName: IAgGridColDef<IOrderTest, 'BeforeRepairStateName'> = {
        headerName: formatMessage({ id: 'order.orderTests.beforeRepairStateName' }),
        field: 'BeforeRepairStateName'
      }

      const afterRepairStateName: IAgGridColDef<IOrderTest, 'AfterRepairStateName'> = {
        headerName: formatMessage({ id: 'order.orderTests.afterRepairStateName' }),
        field: 'AfterRepairStateName'
      }

      return [
        testName,
        beforeRepairStateName,
        afterRepairStateName
      ]
    },
    [formatMessage]
  )

  const tableStyles: React.CSSProperties = useMemo(
    () => ({
      height: order.OrderTests.length > 0
        ? `${(order.OrderTests.length + 2) * 30}px`
        : '100%'
    }),
    [order]
  )

  const onFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent) => {
      event.api.sizeColumnsToFit()
    },
    []
  )

  useEffect(
    () => console.log({ tableStyles }),
    [tableStyles]
  )

  return (
    <AgGridTable
      className={classes.table}
      columnDefs={colDefs}
      onFirstDataRendered={onFirstDataRendered}
      rowData={order.OrderTests}
      styles={tableStyles}
    />
  )
}
