import { Moment } from 'moment'

export interface IFilters {
  from: Moment
  orderNumber: string | null
  status: OrderInsuranceStatuses
  till: Moment,
}

export enum OrderInsuranceStatuses {
  Done = 'Done',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export interface IPagination {
  skip: number
  top: number
}

export type TRequestParams = IFilters & IPagination

export interface IResponse {
  count: number
  data: IOrderItem[]
}

export interface IOrderItem {
  ColorName: string
  Created: string | null
  CustomerName: string
  CustomerPhone: string | null
  CustomerPhoneCode: string | null
  Id: string
  ModelName: string
  Number: string
  OrderStatusName: string
  OrderStatusTypeColor: string | null
}
