import { withAuth } from './with-auth'
import { IRoutesMap } from 'features/@core'

export const makePrivateRoutes = (routes: IRoutesMap) => {
  const privateRoutes: IRoutesMap = { }

  Object.entries(routes).forEach(([path, Component]) => {
    privateRoutes[path] = withAuth(Component)
  })

  return privateRoutes
}
