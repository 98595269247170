import axios from 'axios'

const { REACT_APP_AUTH_API_URL, REACT_APP_AUTH_CLIENT_ID, REACT_APP_BASE_API_URL, REACT_APP_GIT_SHA } = process.env

export const pedantBaseApi = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  headers: {
    'app-type': REACT_APP_AUTH_CLIENT_ID,
    'app-version': REACT_APP_GIT_SHA || 'UNDEFINED',
    'json-naming-strategy': 'pascalcase'
  }
})

export const pedantAuthApi = axios.create({
  baseURL: REACT_APP_AUTH_API_URL,
  headers: {
    'app-type': REACT_APP_AUTH_CLIENT_ID,
    'app-version': REACT_APP_GIT_SHA || 'UNDEFINED'
  }
})

export const pedantInsuranceApi = axios.create({
  baseURL: 'https://ins.base.pedant.ru',
  headers: {
    'app-type': REACT_APP_AUTH_CLIENT_ID,
    'app-version': REACT_APP_GIT_SHA || 'UNDEFINED'
  }
})
