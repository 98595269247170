import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStore } from 'effector-react'
import moment from 'moment'
import { Form } from 'antd'
import { $order } from '../../../store'

export const AboutOrder = () => {
  const { Created, ServiceCenterName } = useStore($order)

  return (
    <Form>
      <Form.Item label={<FormattedMessage id='order.fields.serviceCenterName' />}>
        <span>{ServiceCenterName}</span>
      </Form.Item>

      <Form.Item label={<FormattedMessage id='order.fields.created' />}>
        {Created && moment(Created).utc().format('DD.MM.YY HH:mm:ss')}
      </Form.Item>
    </Form>
  )
}
