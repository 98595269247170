import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStore } from 'effector-react'
import { Col, Form, Input, Row } from 'antd'
import { $order } from '../../../store'
import { ROW_GUTTER } from 'app-constants'

export const AboutDevice = () => {
  const { Appearance, IMEI, OrderMalfunctions, Password, SerialNumber, ReceiverNotes,
    TechnicianNotesForCertificateOfCompletion } = useStore($order)

  const malfunctions = useMemo(
    () => {
      if (!OrderMalfunctions?.length) return ''

      return OrderMalfunctions.map(({ MalfunctionName }) => MalfunctionName).join(', ')
    },
    [OrderMalfunctions]
  )

  return (
    <Form layout='vertical'>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.imei' />}>
            <Input
              readOnly
              value={IMEI}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.serialNumber' />}>
            <Input
              readOnly
              value={SerialNumber}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.password' />}>
            <Input
              readOnly
              value={Password}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item label={<FormattedMessage id='order.fields.appearance' />}>
            <Input
              readOnly
              value={Appearance}
            />
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item label={<FormattedMessage id='order.fields.orderMalfunctions' />}>
            <Input
              readOnly
              value={malfunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id='order.fields.receiverNotes' />}>
            <Input.TextArea
              readOnly
              rows={8}
              value={ReceiverNotes}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={<FormattedMessage id='order.fields.technicianNotesForCertificateOfCompletion' />}>
            <Input.TextArea
              readOnly
              rows={8}
              value={TechnicianNotesForCertificateOfCompletion}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
