import React, { Fragment, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStore } from 'effector-react'
import { Form, Image, List } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { $insurancePhotos, $testsResults } from '../../../store'
import { IOrderPhoto, ITestsResultsItem } from '../../../types'
import { ROW_GUTTER } from 'app-constants'
import * as classes from './style.module.less'

const COLS = 4

export const TestsResults = () => {
  const { modelName, testsResults } = useStore($testsResults)
  const photos = useStore($insurancePhotos)

  const indexedTestResults: [number, ITestsResultsItem][] = useMemo(
    () => {
      if (!testsResults?.length) return []

      return testsResults.map((item, index) => ([index + 1, item]))
    },
    [testsResults]
  )

  const photosFromTests = useMemo(
    () => testsResults
      .filter(test => test.testName === 'Фотоустройства' && Boolean(test.result))
      .map(test => test.result),
    [testsResults]
  )

  const renderTestsListItem = useCallback(
    ([index, data]: [number, ITestsResultsItem]) => (
      <div className={classes.listItem}>
        <span>{`${index}.`}</span>
        <span>{data.testName}</span>
        <span>{data.result && data.result !== 'false' ? (<CheckCircleOutlined />) : <CloseCircleOutlined />}</span>
      </div>
    ),
    []
  )

  const renderPhotosListItem = useCallback(
    (item: IOrderPhoto) => (
      <Image
        src={item.Url}
        wrapperStyle={{ margin: `0 ${ROW_GUTTER / 2}px` }}
      />
    ),
    []
  )

  const renderTestsPhotosListItem = useCallback(
    (url: string) => (
      <Image
        src={url}
        wrapperStyle={{ margin: `0 ${ROW_GUTTER / 2}px` }}
      />
    ),
    []
  )

  return (
    <div className={classes.wrapper}>
      <Form.Item
        label={
          <FormattedMessage
            id='order.testResults.modelName'
            tagName='strong'
          />
        }
      >
        <span>{modelName}</span>
      </Form.Item>

      <FormattedMessage
        id='order.testResults.testsList'
        tagName='strong'
      />

      <List
        dataSource={indexedTestResults}
        grid={{ gutter: ROW_GUTTER, column: COLS }}
        itemLayout='horizontal'
        renderItem={renderTestsListItem}
      />

      {Boolean(photosFromTests.length) && (
        <Fragment>
          <FormattedMessage
            id='order.testResults.testsPhotos'
            tagName='strong'
          />

          <List
            dataSource={photosFromTests}
            grid={{ gutter: ROW_GUTTER, column: 6 }}
            itemLayout='horizontal'
            renderItem={renderTestsPhotosListItem}
          />
        </Fragment>
      )}

      {Boolean(photos.length) && (
        <Fragment>
          <FormattedMessage
            id='order.testResults.insurancePhotos'
            tagName='strong'
          />

          <List
            dataSource={photos}
            grid={{ gutter: ROW_GUTTER, column: 6 }}
            itemLayout='horizontal'
            renderItem={renderPhotosListItem}
          />
        </Fragment>
      )}
    </div>
  )
}
