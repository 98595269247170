import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import classes from './style.module.less'

export interface IAction<T> {
  className?: string
  condition?: (params: { data?: T }) => boolean
  onClick?: (params: { data?: T }) => void
  text?: string
  textFn?: (params: { data?: T }) => string
  type: 'button' | 'link' | 'text'
}

export interface ITableActionsParams<T> {
  actions: IAction<T>[]
}

type TParams<T> = ICellRendererParams & ITableActionsParams<T>

export class TableActions<T> implements ICellRendererComp {
  private params: TParams<T> | null = null

  private wrapper = document.createElement('div')

  init (params: TParams<T>) {
    this.params = params

    this.wrapper.classList.add(classes.wrapper)

    this.renderActions().forEach(action => {
      this.wrapper.appendChild(action)
    })
  }

  getGui () {
    return this.wrapper
  }

  refresh () {
    return false
  }

  renderActions () {
    if (!this.params) return []
    const dispatchingData = this.params?.node?.data

    return this.params.actions
      .filter(({ condition }) => condition === undefined || condition({ data: dispatchingData }))
      .map(action => this.renderAction(action, dispatchingData))
  }

  renderAction ({ className, onClick, text, textFn, type }: IAction<T>, dispatchingData: T | undefined) {
    if (type === 'link') {
      const actionLink = document.createElement('a')

      actionLink.onclick = e => {
        e.preventDefault()

        if (onClick) {
          onClick({
            data: dispatchingData
          })
        }
      }

      actionLink.innerHTML = text || (textFn && textFn({ data: dispatchingData })) || ''
      actionLink.className = className || ''

      return actionLink
    }

    if (type === 'button') {
      const actionButton = document.createElement('button')

      actionButton.onclick = e => {
        e.preventDefault()

        if (onClick) {
          onClick({
            data: dispatchingData
          })
        }
      }

      actionButton.innerHTML = text || (textFn && textFn({ data: dispatchingData })) || ''
      actionButton.className = className || ''

      return actionButton
    }

    const actionSpan = document.createElement('span')
    actionSpan.innerHTML = text || (textFn && textFn({ data: dispatchingData })) || ''
    actionSpan.className = className || ''

    return actionSpan
  }
}
