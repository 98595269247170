import React from 'react'
import * as classes from './style.module.less'

export const AppLoader = () => (
  <div className={classes.loader}>
    <img
      alt='Loading...'
      src='/images/pedant-loading.gif'
    />
  </div>
)
