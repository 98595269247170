import React, { Fragment, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { Link, Location } from '@reach/router'
import { Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { $userRoles, setDrawerVisible } from 'features/@core'
import { IMenuItem } from './types'
import { menuItems } from './menu-items'
import * as classes from './style.module.less'

interface IProps {
  preventNavigation: boolean
}

export const SiderMenu = ({ preventNavigation }: IProps) => {
  const { formatMessage } = useIntl()
  const userRoles = useStore($userRoles)

  const confirmMessage = useMemo(
    () => {
      return formatMessage({ id: 'sider.leaveConfirm' })
    },
    [formatMessage]
  )

  const onMenuItemClick = useCallback(
    ({ key }: MenuInfo) => {
      if (
        (preventNavigation && window.confirm(confirmMessage)) ||
        !preventNavigation
      ) {
        setDrawerVisible(false)

        const clickedItem = menuItems.find(i => i.route === key || i.translateKey === key)

        if (clickedItem?.newTab) {
          window.open(String(key), '_blank')
        }
      }
    },
    [preventNavigation, confirmMessage]
  )

  const menuItemsElements = useCallback(
    (menuItems: IMenuItem[]) => menuItems.map(({
      allowedRoles,
      icon: ItemIcon,
      items,
      newTab = false,
      route,
      translateKey,
      visible
    }) => {
      const content = (
        <Fragment>
          <ItemIcon />

          <FormattedMessage id={translateKey} />
        </Fragment>
      )

      if (
        !visible ||
        !userRoles ||
        (
          allowedRoles &&
          !userRoles.some(role => allowedRoles.includes(role))
        )
      ) {
        return null
      }

      if (items) {
        return (
          <Menu.SubMenu
            key={translateKey}
            title={content}
          >
            {menuItemsElements(items)}
          </Menu.SubMenu>
        )
      }

      return (
        <Menu.Item
          key={route || translateKey}
          onClick={onMenuItemClick}
        >
          {route && !newTab
            ? (
              <Link
                rel='noopener noreferrer'
                target='_blank'
                to={route}
              >
                {content}
              </Link>
            )
            : content}
        </Menu.Item>
      )
    }),
    [onMenuItemClick, userRoles]
  )

  return (
    <Location>
      {routerProps => (
        <Fragment>
          <Menu
            className={classes.siderMenu}
            mode='inline'
            selectedKeys={[routerProps.location.pathname]}
            theme='dark'
          >
            {menuItemsElements(menuItems)}
          </Menu>
        </Fragment>
      )}
    </Location>
  )
}
