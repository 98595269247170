import { pedantBaseApi } from 'api'
import { ITokenData } from '../types'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { refreshTokenFx } from '../store'

const logIfDev = (...args: any[]) =>
  process.env.REACT_APP_ENV === 'development' && console.log(...args)

const createAxiosInterceptors = () => {
  let tokenRefreshingPromise: Promise<AxiosResponse<ITokenData>> | null = null

  const requestInterceptor = async (config: AxiosRequestConfig) => {
    if (tokenRefreshingPromise) {
      await tokenRefreshingPromise
    }

    const authData: ITokenData | undefined = localStorage.auth && JSON.parse(localStorage.auth)

    if (authData?.access_token && authData.token_type) {
      config.headers = {
        ...config.headers,
        Authorization: `${authData.token_type} ${authData.access_token}`
      }
    }

    return config
  }

  const responseInterceptor = async (error: AxiosError) => {
    let tokenRefreshCaller = false

    if (error?.response?.status !== 401) return Promise.reject(error)

    if (!tokenRefreshingPromise) {
      logIfDev('Token refreshing started...')
      tokenRefreshCaller = true
      tokenRefreshingPromise = refreshTokenFx()
    }

    try {
      const { data } = await tokenRefreshingPromise

      if (tokenRefreshCaller) {
        logIfDev('Token refreshing success!')
        tokenRefreshingPromise = null
      }

      error.config.headers = {
        ...error.config.headers,
        Authorization: `${data.token_type} ${data.access_token}`
      }

      return axios(error.config)
    } catch (e) {
      if (!tokenRefreshCaller) return Promise.reject(e)

      logIfDev('Token refreshing error:', `"${e.toString()}"`)
      localStorage.removeItem('auth')

      if (window.location.pathname !== '/login') {
        window.location.href = '/login'
      }

      return Promise.reject(e)
    }
  }

  return {
    requestInterceptor,
    responseInterceptor
  }
}

let apiConfigured = false

export const configureApi = () => {
  if (apiConfigured) return

  const { requestInterceptor, responseInterceptor } = createAxiosInterceptors()

  pedantBaseApi.interceptors.request.use(requestInterceptor)
  pedantBaseApi.interceptors.response.use(undefined, responseInterceptor)
  apiConfigured = true
}
