import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import moment from 'moment'
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community'
import { AgGridTable, ColoredCircleRenderer, IColoredCircleRendererParams } from 'features/ag-grid'
import { $ordersList, $tableLoading } from '../../store'
import { IAgGridColDef, IAgGridRowDoubleClickedEvent } from 'types'
import { IOrderItem } from '../../types'

export const Table = () => {
  const { formatMessage } = useIntl()

  const loading = useStore($tableLoading)
  const rowData = useStore($ordersList)

  const defaultColDef: ColDef = useMemo(
    () => ({
      resizable: true
    }),
    []
  )

  const colDefs: ColDef[] = useMemo(
    () => {
      const number: IAgGridColDef<IOrderItem, 'Number'> = {
        headerName: formatMessage({ id: 'orders.table.number' }),
        field: 'Number'
      }

      const created: IAgGridColDef<IOrderItem, 'Created'> = {
        headerName: formatMessage({ id: 'orders.table.created' }),
        field: 'Created',
        valueGetter: params => params.data.Created ?
          moment(params.data.Created).utc().format('DD.MM.YYYY HH:mm:ss')
          : ''
      }

      const orderStatus: IAgGridColDef<IOrderItem, 'OrderStatusName'> = {
        headerName: formatMessage({ id: 'orders.table.status' }),
        field: 'OrderStatusName',
        cellRenderer: ColoredCircleRenderer,
        cellRendererParams: {
          getNameAndColor: (data) => ({
            color: data?.OrderStatusTypeColor,
            name: data.OrderStatusName
          })
        } as IColoredCircleRendererParams<IOrderItem, 'OrderStatusName'>
      }

      const model: IAgGridColDef<IOrderItem, 'ModelName'> = {
        headerName: formatMessage({ id: 'orders.table.model' }),
        field: 'ModelName'
      }

      const color: IAgGridColDef<IOrderItem, 'ColorName'> = {
        headerName: formatMessage({ id: 'orders.table.color' }),
        field: 'ColorName'
      }

      const customer: IAgGridColDef<IOrderItem, 'CustomerName'> = {
        headerName: formatMessage({ id: 'orders.table.customer' }),
        field: 'CustomerName'
      }

      return [
        number,
        created,
        orderStatus,
        model,
        color,
        customer
      ]
    },
    [formatMessage]
  )

  const onFirstDataRendered = useCallback(
    ({ api }: FirstDataRenderedEvent) => {
      api.sizeColumnsToFit()
    },
    []
  )

  const onRowDoubleClicked = useCallback(
    ({ data }: IAgGridRowDoubleClickedEvent<IOrderItem>) => {
      window.open(`/order/${data.Id}`, '_blank')
    },
    []
  )

  return (
    <AgGridTable
      columnDefs={colDefs}
      defaultColDef={defaultColDef}
      loading={loading}
      onFirstDataRendered={onFirstDataRendered}
      onRowDoubleClicked={onRowDoubleClicked}
      rowData={rowData}
    />
  )
}
