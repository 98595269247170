import { createEvent } from 'effector'
import { IOrderPhoto, ITestsResults } from '../types'

export const askConfirmation = createEvent<{ approved: boolean }>()
export const confirmationReceived = createEvent<{ approved: boolean }>()
export const initialize = createEvent()
export const removeWork = createEvent<string>()
export const reset = createEvent()
export const setInsurancePhotos = createEvent<IOrderPhoto[]>()
export const setTestsResults = createEvent<ITestsResults>()
