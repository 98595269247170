import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BaseTemplate, DocumentTitle } from 'features/ui'
import { Root } from '../organisms'

export const OrdersPage = () => {
  return (
    <BaseTemplate
      fullHeightLayer
      pageTitle={<FormattedMessage id='orders.title' />}
    >
      <DocumentTitle useIntlKey>
        orders.title
      </DocumentTitle>

      <Root />
    </BaseTemplate>
  )
}
