import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { Spinner } from 'features/ui'
import * as classes from './style.module.less'

type TProps = AgGridReactProps & {
  className?: string
  loading?: boolean
  styles?: React.CSSProperties
}

export const AgGridTable = ({ className, loading, styles, ...tableProps } : TProps) => {
  const { formatMessage } = useIntl()

  const localeCallback = useCallback(
    (key: string) => formatMessage({ id: `agGrid.${key}` }),
    [formatMessage]
  )

  const wrapperStyles: React.CSSProperties = useMemo(
    () => {
      if (!styles) return { height: '100%' }

      return {
        height: '100%',
        ...styles
      }
    },
    [styles]
  )

  return (
    <div
      className={className}
      style={wrapperStyles}
    >
      <Spinner
        spinning={loading}
        wrapperClassName={classes.wrapper}
      >
        <div
          className='ag-theme-material'
          style={{ height: '100%' }}
        >
          <AgGridReact
            localeTextFunc={localeCallback}
            {...tableProps}
          />
        </div>
      </Spinner>
    </div>
  )
}
