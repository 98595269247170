import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tabs as AntdTabs } from 'antd'
import * as Tabs from '../tabs'
import * as classes from './style.module.less'

export const OrderTabs = () => {

  return (
    <AntdTabs className={classes.tabHost}>
      <AntdTabs.TabPane
        key='AboutOrder'
        tab={<FormattedMessage id='order.tabs.aboutOrder'/>}
      >
        <Tabs.AboutOrder />
      </AntdTabs.TabPane>

      <AntdTabs.TabPane
        key='AboutDevice'
        tab={<FormattedMessage id='order.tabs.aboutDevice' />}
      >
        <Tabs.AboutDevice />
      </AntdTabs.TabPane>

      <AntdTabs.TabPane
        key='ServicesAndParts'
        tab={<FormattedMessage id='order.tabs.servicesAndParts' />}
      >
        <Tabs.ServicesAndParts />
      </AntdTabs.TabPane>

      <AntdTabs.TabPane
        key='Testing'
        tab={<FormattedMessage id='order.tabs.testing' />}
      >
        <Tabs.Testing />
      </AntdTabs.TabPane>

      <AntdTabs.TabPane
        key='TestsResults'
        tab={<FormattedMessage id='order.tabs.testsResults' />}
      >
        <Tabs.TestsResults />
      </AntdTabs.TabPane>
    </AntdTabs>
  )
}
