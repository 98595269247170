import React from 'react'
import { useStore } from 'effector-react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { $locale, $translations } from 'features/@core/features/intl'

interface IProps {
  children?: React.ReactNode
}

export const IntlProvider = ({ children }: IProps) => {
  const locale = useStore($locale)
  const translations = useStore($translations)

  return (
    <ReactIntlProvider
      children={children}
      locale={locale}
      messages={translations}
      textComponent='span'
    />
  )
}
