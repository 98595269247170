import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { $translations } from 'features/@core'
import { useStore } from 'effector-react'

interface IProps {
  useIntlKey?: boolean,
  values?: { [key: string]: string | undefined },
  children: string
}

export const DocumentTitle = ({
  useIntlKey,
  values,
  children
}: IProps) => {
  const translations = useStore($translations)
  const { formatMessage } = useIntl()

  const titleContent = useMemo(
    () => useIntlKey
      ? formatMessage({ id: children }, values)
      : children
    ,
    [useIntlKey, formatMessage, values, children]
  )

  return (
    <Helmet
      defaultTitle={translations.projectName}
      title={titleContent}
      titleTemplate={`%s — ${translations.projectName}`}
    />
  )
}
