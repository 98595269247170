import React, { useCallback, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { Button, Col, Modal, Row } from 'antd'
import { $loadedAppSettings } from 'features/@core'
import { $order, askConfirmation, confirmationReceived } from '../../store'
import { ROW_GUTTER } from 'app-constants'
import * as classes from './style.module.less'

export const TopBar = () => {
  const { formatMessage } = useIntl()

  const appSettings = useStore($loadedAppSettings)
  const order = useStore($order)

  const onApproveClick = useCallback(
    () => askConfirmation({ approved: true }),
    []
  )

  const onRejectClick = useCallback(
    () => askConfirmation({ approved: false }),
    []
  )

  useEffect(
    () => {
      let destroyConfirmation: () => void

      const confirmationSub = askConfirmation.watch(({ approved }) => {
        const { destroy } = Modal.confirm({
          content: approved
            ? formatMessage({ id: 'order.topBar.confirmationApprove' })
            : formatMessage({ id: 'order.topBar.confirmationReject' }),
          onOk: () => {
            confirmationReceived({ approved })
            if (destroyConfirmation) destroyConfirmation()
          },
          title: formatMessage({ id: 'order.topBar.confirmationTitle' })
        })

        destroyConfirmation = destroy
      })

      return () => {
        confirmationSub.unsubscribe()
        if (destroyConfirmation) destroyConfirmation()
      }
    },
    [formatMessage]
  )

  if (order.OrderStatusId !== appSettings.InsuranceCheckOrderStatusId) {
    return null
  }

  return (
    <Row
      className={classes.wrapper}
      gutter={ROW_GUTTER}
    >
      <Col>
        <Button
          onClick={onApproveClick}
          type='primary'
        >
          <FormattedMessage id='order.topBar.approve' />
        </Button>
      </Col>

      <Col>
        <Button
          danger
          onClick={onRejectClick}
          type='primary'
        >
          <FormattedMessage id='order.topBar.reject' />
        </Button>
      </Col>
    </Row>
  )
}
