import React, { useCallback, useMemo } from 'react'
import { useStore } from 'effector-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Input, Typography } from 'antd'
import { $authError, getTokenFx } from 'features/@core'
import { ILoginData } from '../../types'
import * as classes from './style.module.less'

export const Root = () => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()

  const authError = useStore($authError)
  const loading = useStore(getTokenFx.pending)

  const requiredRuleMessage = useMemo(
    () => ({
      username: formatMessage({ id: 'login.required.username' }),
      password: formatMessage({ id: 'login.required.password' })
    }),
    [formatMessage]
  )

  const onFinish = useCallback(
    (values: ILoginData) => {
      getTokenFx(values)
    },
    []
  )

  return (
    <div className={classes.wrapper}>
      <Form
        className={classes.content}
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          label={<FormattedMessage id='login.username' />}
          name='username'
          required
          rules={[{ required: true, message: requiredRuleMessage.username }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id='login.password' />}
          name='password'
          required
          rules={[{ required: true, message: requiredRuleMessage.password }]}
        >
          <Input type='password' />
        </Form.Item>

        {authError && (
          <Form.Item>
            <Typography.Text type='danger'>
              <FormattedMessage id='login.error.text' />
            </Typography.Text>
          </Form.Item>
        )}

        <Button
          htmlType='submit'
          loading={loading}
          type='primary'
        >
          <FormattedMessage id='login.signIn' />
        </Button>
      </Form>
    </div>
  )
}
