import React from 'react'
import { IRoutesMap } from 'features/@core'

export const mapRoutes = (routesObj: IRoutesMap) => {
  return Object.entries(routesObj).map(([path, Component]) =>
    <Component
      key={path}
      path={path}
    />
  )
}
