import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { ConfigProvider as AntdConfigProvider } from 'antd'
import { $antdLocale, $appLoading, initializeApp } from './features/@core'
import { IntlProvider } from 'features/intl'
import { AppLoader } from './features/ui'
import { Router } from './router'
import './styles/vendor'

function App () {
  const antdLocale = useStore($antdLocale)
  const loading = useStore($appLoading)

  useEffect(
    () => {
      initializeApp()
    },
    []
  )

  if (loading) {
    return (
      <AppLoader />
    )
  }

  return (
    <AntdConfigProvider locale={antdLocale}>
      <IntlProvider>
        <Router />
      </IntlProvider>
    </AntdConfigProvider>
  )
}

export default App
