import { createEffect } from 'effector'
import dayjs from 'dayjs'
import { selfApi } from 'api'
import { getLocale } from '../utils'

export const getLocaleFx = createEffect({
  handler () {
    const full = getLocale()

    return {
      full,
      short: full.split('-')[0]
    }
  }
})

export const changeLocalizationFx = createEffect({
  async handler ({ locale, localeCode }: { locale: string, localeCode: string }) {
    const antdLocaleProviderName = localeCode.replace('-', '_')
    const intlPath = './packages'

    const antdIntlLanguageModule = await import(`${intlPath}/antd/${antdLocaleProviderName}`)

    const { data } = await selfApi.get<{ [key: string]: string }>(`/locales/${localeCode.toLowerCase()}.json`)

    if (localeCode !== 'en-US') {
      await import(`${intlPath}/dayjs/${locale}`)
    }

    dayjs.locale(locale)

    return {
      antdIntlLanguageModule: antdIntlLanguageModule.default,
      translations: data
    }
  }
})
